'use strict';

window.popin = window.popin || {};
window.popin.formSubmitted = false;
window.popin.useDefault = false;

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

// reload page based on the selected language
function setLanguage(lg) {
    if(!lg) return;
    var languageAnchor = $('.country-selector a[data-locale="' + lg + '"]');

    if (languageAnchor.length) {
        languageAnchor.trigger('click');
    } else {
        window.location.reload();
    }
}

// this script only load country selector popin
$(document).ready(function () {
    // click on validate form
    $(document).on('click', '.js-shipping-country-change', function (e) {
        $(this).trigger('country:change');
    });

    $(".js-shipping-country-select").select2({
        minimumResultsForSearch: -1,
        templateResult: function (country) {
            if (!country.id) { return country.text; }
            var $span = $(
                '<i class="flag-icon flag-icon-' + country.id.toLowerCase() + '"></i>' +
                '<span class="flag-text">' + country.text + "</span>"
            );
            return $span;
        },
        templateSelection: function (country) {
            if (!country.id) { return country.text; }
            var $span = $(
                '<i class="flag-icon flag-icon-' + country.id.toLowerCase() + '"></i>' +
                '<span class="flag-text">' + country.text + "</span>"
            );
            return $span;
        }
    });

    // close the popin without/with clicking on validate button
    $('#shippingCountryPopin').on('hide.bs.modal', function (e) {
        if (window.popin.formSubmitted === true) {
            // nothing to do
            window.popin.useDefault = false;
        } else {
            // nothing to do
            window.popin.useDefault = true;
            // trigger form submit on default country
            e.stopImmediatePropagation();
            $('.js-shipping-country-change').trigger('country:change');
        }
    });


    // shipping country form validation event
    $(document).on('country:change', function (e) {
        e.preventDefault();
        window.popin.formSubmitted = true;
        var $form = $('.js-shipping-country-form');
        var defer = $.Deferred();
        $.ajax({
            url: $form.attr('action'),
            data: window.popin.useDefault === false ? $form.serializeArray() : { useDefault: true },
            method: 'POST',
            success: function (data) {
                window.popin.formSubmitted = false;
                if (!data.error) {
                    defer.resolve();
                    setLanguage(data.lang);
                } else {
                    defer.reject();
                }
            },
            error: function () {
                window.popin.formSubmitted = false;
                defer.reject();
            }
        });
    });

    if (getCookie('TEC_ShippingCountry')) return;

    $('#shippingCountryPopin').modal('show');
});

